import React, {useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";

import { BodyText, Modal } from "@hm-group/fabric-components/brand/hm";

import instance from '../../../services/UserService';
import PageContainer from '../../../components/PageContainer';
import ShowroomList from './ShowroomList';

export default function Showrooms({ submenu, userShowrooms }) {
  const [showrooms, setShowrooms] = useState([]);
  const [successMessage, setSuccessMessage] = useState();

  const location = useLocation();

  // On component load
  useEffect( () => {
    // Reset success message in state
    if(location.state) {
      setSuccessMessage(location.state);
      window.history.replaceState({}, '/admin/showrooms');
    }

    // Get all showrooms and filter them based on showrooms that the user has access to
    const getShowrooms = () => {
      instance
      .get('/getAllShowrooms', { params: {
        'size': 1000,
        'sort': 'showroomName,ASC'
      }})
      .then(response => {
        const filteredShowrooms = response.data.content.filter(showroom => userShowrooms.some(userShowroom => userShowroom.value === showroom.showroomId));
        setShowrooms(filteredShowrooms);
      })  
      .catch(error => {
        console.log(error);
      })
    }

    getShowrooms();
  },[])

  return (
    <PageContainer title="Showrooms" submenu={submenu}>
      {successMessage && (
        <Modal
          isOpen={!!successMessage}
          heading="Success!"
          content={<BodyText dangerouslySetInnerHTML={{__html: `${successMessage}`}}/>}
          closeLabel="Close"
          size="medium"
          onClose={() => setSuccessMessage()}
        />
      )}
      
      <ShowroomList showrooms={showrooms} />
    </PageContainer>
  );
}
