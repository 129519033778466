import React from 'react'

import { HeadingLarge, Preamble } from "@hm-group/fabric-components/brand/hm";

export default function AccessRestrictedPage({ title, text }) {
    return (
        <div style={{ width: '100%', height: '100vh', padding: '20vh 30%', textAlign: 'center' }}>
            <HeadingLarge>{title || "Access Restricted"}</HeadingLarge>
            <Preamble dangerouslySetInnerHTML={{__html: text || "You don't have permission to view this page."}} />
        </div>
    )
}
